.payIcons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  max-width: 1024px;
  width: 100%;
  margin-top: 24px;
  &__icon {
    margin-bottom: 10px;
  }
  @media (max-width: 1085px) {
    padding: 0 40px;  
  }
  @media (max-width: 730px) {
    flex-wrap: wrap;
    margin-top: 20px;  
  }
}