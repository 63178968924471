.yandexMap {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center; 
 width: 100%;
 margin-top: 45px;
 @media (max-width: 1085px) {
  margin-top: 27px;  
}
 &__title {
  color: #FFF;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
  @media (max-width: 1085px) {
    margin-bottom: 10px;  
  }
 }
 &__map {
  max-width: 3500px;
  width: 100%;
  height: 280px;
 }
 
}