.footer {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 margin-top: 50px;
 max-width: 950px;
 width: 100%;
 @media (max-width: 1085px) {
  padding: 0 40px;
  padding: 0 20px;
}
@media (max-width: 730px) {
  margin-top: 20px;  
}
 &__wrapperInfo {  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 730px) {
    flex-direction: column;
    justify-content: flex-start;  
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    &__title {
      color: #3D3F48;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 23px;
      @media (max-width: 1085px) {
        font-size: 14px;  
      }
      @media (max-width: 730px) {
        font-size: 12px;
        margin-bottom: 15px;  
      }
    }
    @media (max-width: 730px) {
      margin-bottom: 25px;  
    }
    &__text {
      color: #3D3F48;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width: 1085px) {
        font-size: 12px;  
      }
      @media (max-width: 730px) {
        font-size: 10px;          
      }
    }
    .click {
      cursor: pointer;
    }
  }
 }
 &__wrapperText {  
  width: 100%; 
  margin: 80px 0;
  @media (max-width: 1085px) {
    margin: 60px 0; 
  }
  @media (max-width: 730px) {
    margin-top: 0;  
  }    
  .text {
    color: rgba(40, 56, 85, 0.50);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    list-style-type: disc;
    list-style-position: outside;
    @media (max-width: 1085px) {
      font-size: 10px;  
    }
    @media (max-width: 730px) {
      font-size: 8px;        
    }
  }
 }
}