.searchForm {
  max-width: 4000px;
  width: 100%;
  padding: 0 120px;
  margin-top: 10px;  

  @media (max-width: 730px) {
    padding: 0 20px;
  }

  .wrapperForm {
    width: 100%;
    box-shadow: 0px 0px 100px 0px black;
    border-radius: 10px;    
    position: relative;
    top: -50px;
    @media (max-width: 730px) {
      top: 0;
    }    
  }
}