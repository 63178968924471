.header {
  background-image: url("../../assets/img/backgroundHeader.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 4000px;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 730px) {
    justify-content: center;
    align-items: flex-start;
    background-image: url("../../assets/img/backgroundHeaderMobile.jpg");
    height: 238px;
    //---------будет меняться -----!!!!!
    margin-bottom: 150px;
    //-----------------------------
  }

  &__wrapperLogo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 69px;
    margin-bottom: 60px;

    @media (max-width: 1085px) {
      margin-left: 40px;
    }

    @media (max-width: 730px) {
      display: none;
    }

    .logo {
      margin-bottom: 60px;
    }

    .title {
      color: #FFF;
      text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      font-family: "Open Sans";
      font-size: 29px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &__wrapperMenu {
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(35px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 440px;
    height: 100%;
    padding: 0 30px 0 50px;
    border-bottom-right-radius: 40px;

    @media (max-width: 1085px) {
      padding: 0 40px;
    }

    @media (max-width: 730px) {
      align-items: center;
      padding: 0px 30px 0px 30px;
      min-height: 400px;
      border-radius: 0;
    }

    .logoMobile {
      display: none;

      @media (max-width: 730px) {
        display: block;
        margin-bottom: 12px;
      }
    }

    .wrapperInfo {
      &__info {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 19px;

        @media (max-width: 730px) {
          margin-bottom: 12px;
        }

        .icon {
          margin-right: 15px;
        }

        .wrapperText {
          .text {
            color: #FFF;
            font-family: "Open Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .cursor {
            cursor: pointer;
          }
        }
      }
    }

    .messengersIcons {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      // margin-top: 20px;

      &__icon {
        margin-right: 50px;
        cursor: pointer;
      }

      &__icon:last-child {
        margin-right: 0;
      }

      &__icon:hover {
        .iconInst {
          display: none;
        }

        .iconInstHover {
          display: block;
        }
      }

      .iconViber:hover {
        circle {
          fill: white;
        }

        path {
          fill: #C15CFF;
        }
      }

      .iconTelegram:hover {
        circle {
          fill: white;
        }

        path {
          fill: #00A3FF;
        }
      }

      .iconInst:hover {
        display: none;
      }

      .iconInstHover {
        display: none;
      }

      .iconInstHover:hover {
        display: block;
      }
    }
  }
}